import { AnyAction } from "redux";
import APIFc from "../../services/APIFc";
import { Dispatch } from "react";
import apiFc from "../../services/APIFc";
import { encryptData } from "../../functions/cryptoUtils";
import { createAction } from "@reduxjs/toolkit";


export const setPedidoActual = (pedido: any) => {
    return {
        type: "SET_PEDIDO_ACTUAL",
        payload: encryptData(pedido)
    }
}
export const setPedidoActualRenglones = (pedidoRenglones: any) => {
    return {
        type: "SET_PEDIDO_ACTUAL_RENGLONES",
        payload: encryptData(pedidoRenglones)
    }
}
export const setPendientes = (pendientes: any) => {
    return {
        type: "SET_PENDIENTES",
        payload: encryptData(pendientes)
    }
}
export const setTransportesCliente = (transportes: any) => {
    return {
        type: "SET_TRANSPORTE_CLIENTE",
        payload: encryptData(transportes)
    }
}
export const obtenerFormaFact = (formaFact: any) => {
    return {
        type: "OBTENER_FORMA_FACT",
        payload: encryptData(formaFact)
    }
}
export const setCondicionVenta = (condicionVenta: any) => {
    return {
        type: "SET_CONDICION_VENTA",
        payload: encryptData(condicionVenta)
    }
}
export const setPedidosDespachados = (pedidosDespachados: any) => {
    return {
        type: "SET_PEDIDOS_DESPACHADOS",
        payload: encryptData(pedidosDespachados)
    }
}
export const setPedidosSeguimientoCancelado = (pedidosSeguimientoCancelado: any) => {
    return {
        type: "SET_PEDIDOS_SEGUIMIENTO_CANCELADO",
        payload: encryptData(pedidosSeguimientoCancelado)
    }
}
export const restart = () => {
    return {
        type: "RESET_STATE",
    }
}

// Acción para actualizar los pedidos históricos
export const actualizarPedidos = createAction<any[]>('actualizarPedidos');

// Acción para actualizar los pedidos despachados
export const actualizarPedidosDespachados = createAction<any[]>('actualizarPedidosDespachados');

export const fetchPedidoActual = () => {
    return (dispatch: Dispatch<any>) => {
        apiFc.get(`/web/pedido/actual/renglon`)
            .then(res => {
                dispatch(setPedidoActualRenglones(res.data))
            }).catch(err => {
                dispatch(setPedidoActualRenglones(null))
            }).catch(err => {


            });
    }
};


export const fetchPendientes = () => {
    return (dispatch: Dispatch<any>) => {
        apiFc.get(`/web/pedido/pendientes`)
            .then(res => {
                dispatch(setPendientes(res.data))
            }).catch(err => {
                dispatch(setPendientes([]))
            })
    }
};


export const fetchTransportesCliente = () => {
    return (dispatch: Dispatch<any>) => {
        apiFc.get(`/web/cliente/transportes`)
            .then(res => {
                dispatch(setTransportesCliente(res.data))
            }).catch(err => {
                dispatch(setTransportesCliente(null))
            })
    }
};


export const fetchCondicionVenta = () => {
    return (dispatch: Dispatch<any>) => {
        apiFc.get(`/web/cliente/condicionventa`)
            .then(res => {
                dispatch(setCondicionVenta(res.data))
            }).catch(err => {
                dispatch(setCondicionVenta(null))
            })
    }
};
