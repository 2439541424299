import { useDispatch } from "react-redux";
import APIFc from "../../services/APIFc";
import { setUserInfo } from "../actions/auth";

const INIT_STATE: any = {
    token: undefined,
    loginText: undefined,
    user: undefined,
    version: undefined,
};

export const authreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return { ...state, token: action?.payload  , loginText:""};
        
        case 'SET_LOGIN_TEXT':
            return { ...state, loginText:action?.payload};
        
        case 'USER_GET_INFO':
            return {...state};

        case 'USER_SET_INFO':
            return { ...state, user: action?.payload };
        
        case 'LOGOUT': 
         return {...state, token: null, user:null, loginText: action?.payload}

        case 'SET_VERSION': 
         return {...state, version: action?.payload}
        
        default:
            return state;
    }
};

