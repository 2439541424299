import { Fragment, useEffect, useRef, useState } from "react";
import {
    Card, Col, Tabs, Tab, Table, Pagination, FormGroup,
    Row, Form, Button, OverlayTrigger, Tooltip, InputGroup, Modal, Popover, PopoverBody, Dropdown, Spinner
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import apiFc from "../../../../services/APIFc";
import { errorPedido, filterProduct } from "../../../../redux/actions/grid";
import Swal from "sweetalert2";
import React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { isMobile } from 'react-device-detect';
import DetalleArticulo from "../../../common/DetalleArticulo";
import { AlertDialogInterface } from "../../../common/Interfaces";
import { TablaDataBasica } from "../../../common/TablaDataBasica";
import { formatearFecha } from "../../../../functions/Procedimientos";
import { decryptData } from "../../../../functions/cryptoUtils";



const ErrorPedidos = () => {
    //Informacion del cliente
    const  user = useSelector((store: any) => decryptData(store.authreducer.user));

    //Importo esta funcion para dispara os cambios en el redux
    const dispatch = useDispatch();
    //aplico el useselector sobre este valor por que ahi es donde el Axios+Redux almacenen el resultado de la busqueda
    const  gridPedidoArticulosBusqueda  = useSelector((store: any) => decryptData(store.gridreducer.gridPedidoArticulosBusqueda));
    //la busqueda la vamos a hacer localmente en la pantalla no vamos a usar redux -- Error pero unstable_ownerDocument, lo necesitamos
    const [sugerenciaGrupoArticulo, setSugerenciaGrupoArticulo] = useState<any>([]);
    const [isLoadingArticulosGrupoSeleccionado, setIsLoadingArticulosGrupoSeleccionado] = useState(false);
    const [articulosGrupoSeleccionado, setArticulosGrupoSeleccionado] = useState<number>();

    useEffect(() => {
        if (articulosGrupoSeleccionado)
            apiFc.get(`/articuloGrupos/${articulosGrupoSeleccionado}/articulos`).then(res => {
                dispatch(filterProduct(res.data));
                setIsLoadingArticulosGrupoSeleccionado(false)
            })
    }, [articulosGrupoSeleccionado])

    const [searchType, setSearchType] = useState({
        value: "1",
        label: "Buscar por codigo/descripcion"
    });

    //UseState utilizado para definir orden de los productos renglones del pedido
    const [ordenRenglonesPedido, setOrdenRenglonesPedido] = useState<{ value: string, label: string }>({
        value: "1",
        label: "Abcedario"
    });


    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<any>([]);

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    useEffect(() => {
    }, [sugerenciaGrupoArticulo, options])


    //transportes
    // const { transportesDisponibles, condicionesVenta, pedidoActualRenglones } = useSelector((store: any) => store.pedidoreducer);
    // const [requiereFechaRetiro, setRequiereFechaRetiro] = useState<boolean>(false);
    // const [requiereDetalleRetiro, setRequiereDetalleRetiro] = useState<boolean>(false);

    //productSearch lo utilizo para administrar el input del usuario en la guirlla de busqueda
    const [productSearch, setProductSearch] = useState<any>('');

    // aqui en donde localmente pongo el valor de  gridPedidoArticulosBusqueda para dibujar en la tabla
    const [data, setData] = useState<any[]>();

    //Administrador de las tabs
    const [tabKey, setTabKey] = useState('tCtaCte');


    //Administrador de las tabs
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
        id: "1",
        visible: false,
        severity: 'success', // severity="error" / "warning" / "info" / "success"
        timeOut: 2000, // severity="error" / "warning" / "info" / "success"
        cabecera: "Agregado!",
        texto: "el producto fue agregado.",
    });

    const [errorPedidoLista, setErrorPedidoLista] = useState([]);

    useEffect(() => {
        apiFc.get(`/pedidoErrores/cliente/${user.clienteCodigo}`)
            .then(res => {
                setErrorPedidoLista(res.data)
            }).catch(err => {

            });
    }, [])

    useEffect(() => {
    }, [errorPedidoLista])

    //si existe un set de productSeatrch entonces disparo la llamda a axios para buscar el articulo y de esa manera
    // disparo el evento filtratr con el reusltado
    useEffect(() => {
        try {
            apiFc.get(`/web/articulos/consulta?filter=${productSearch}`).then(res => {
                dispatch(filterProduct(res.data));
            })
        } catch (error) {
        }
    }, [productSearch])

    useEffect(() => {
        setAlertDialog({ ...alertDialog, visible: false })
        window.scrollTo(0, 0)
    }, [tabKey])

    useEffect(() => {

    }, [alertDialog])

    //si alguien disparo un cambio en la busqueda entonces refresco la pantalla,m pero antes asigno a Data el valor obtenido
    // useEffect(() => {
    //     if (gridPedidoArticulosBusqueda.length > 0) {
    //         //creo una lista con los articulos buscados pero con un nuevo atributo de la cantidad pedida
    //         let grid = gridPedidoArticulosBusqueda.map((articulo: any) => ({ ...articulo, cantidadPedida: pedidoActualRenglones?.find((renglon: any) => renglon.articuloCodigo === articulo.articuloCodigo)?.pedidoRenglonCantidad || 0 }))
    //         setData(grid)
    //     }
    // }, [gridPedidoArticulosBusqueda]);

    //si alguien disparo un cambio en la busqueda entonces refresco la pantalla,m pero antes asigno a Data el valor obtenido
    // useEffect(() => {
    // }, [pedidoActualRenglones, ordenRenglonesPedido]);

    //a efectos plracticos de refrescar a pantalla
    useEffect(() => {
    }, [data])

    //funcion para manejar el buscador delinput
    // const handleInput = (event: any) => {
    //     if (event.target.value.length !== 0)
    //         setProductSearch(event.target.value);
    // };

    //Funcion para mostrar al enviar pedido esto deberia ser una funcion general
    function Mensaje(props: any) {
        const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
            props,
            ref,
        ) {
            return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
        });

        const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setAlertDialog({ ...alertDialog, visible: false });
        };
        return (
            <Snackbar
                open={alertDialog.visible}
                autoHideDuration={alertDialog.timeOut}
                onClose={handleClose}
                //Aca decido si es mobile muestro arriba el cartelito, sino abajo
                anchorOrigin={(isMobile) ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleClose}
                    severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
                    sx={{ width: '100%' }}>
                    <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
                </Alert>
            </Snackbar>
        )
    }



    const [showDetalleArticulo, setShowDetalleArticulo] = useState<{ visible: boolean, codArticulo: string, fullScreen: string }>();

    useEffect(() => {
    }, [showDetalleArticulo])

    function handleShowDetalleArticulo(articuloCodigo: string) {
        setShowDetalleArticulo({ ...showDetalleArticulo, visible: true, codArticulo: articuloCodigo, fullScreen: "true" })
    }

    function openModalError() {
        dispatch(errorPedido(data))
      };

    //Primaryalert
    function Primaryalert() {
        Swal.fire({
            title: "Excelente!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText: "Volver al inicio",
            cancelButtonColor: "#38cab3",
            text: "Su pedido fue enviado correctamente!",
        }).then((result) => {
            let path = `${process.env.PUBLIC_URL}/principal/inicio`;
        });
    }


    const errorPedidoColumnas: any = [
        {
            Header: "Origen",
            accessor: (pedidoError: any) => {
                switch (pedidoError.pedidoErrorOrigen) {
                    case 0: { return ("Vendedor") }
                    case 1: { return ("Sistema Precio") }
                    case 2: { return ("Sistema Web") }
                    case 3: { return ("Ferretera") }
                    case 4: { return ("Pedido devuelto") }
                    default: { return ("No encontrado") }
                }
            },
            className: "wd-10p text-center borderrigth",
            rowClassName: 'text-center',
        },
        {
            Header: "Nro Pedido",
            accessor: "pedidoErrorNroMovVenta",
            className: "wd-10p  text-center borderrigth",
            rowClassName: 'text-center',
        },
        {
            Header: "Fecha de carga ",
            accessor: (pedidoError: any) => {
                return (formatearFecha({
                    date: pedidoError.pedidoErrorFechaCarga,
                }))
            },//"pedidoErrorFechaCarga",
            className: "wd-10p  text-center borderrigth",
            rowClassName: 'text-center',
        },
        {
            Header: "Tipo",
            accessor: "pedidoErrorTipoID.pedidoErrorTipoDetalle",
            className: "wd-10p  text-center borderrigth",
            rowClassName: 'text-center',
        },
        {
            Header: "Estado",
            accessor: (pedidoError: any) => {
                switch (pedidoError.pedidoErrorEstado) {
                    case 0: { return ("Creado") }
                    case 1: { return ("Pendiente revision") }
                    case 2: { return ("En resolucion") }
                    case 20: { return ("Solucionado") }
                    default: { return ("No encontrado") }
                }
            },
            className: "wd-10p  text-center borderrigth",
            rowClassName: 'text-center',
        },
        // {
        //     Header: "Detalle",
        //     accessor: "hola",
        //     className: "wd-10p text-center borderrigth",
        //     rowClassName: 'text-center',
        //     Cell: (row: any) => (
        //         <button onClick={() => openModalError}>Enviar detalle</button>
        //     )
        // }
    ];



    return (
        <div>
            {/* <!-- breadcrumb --> */}
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">
                        Error Pedidos
                    </span>
                    {alertDialog.visible && <Mensaje ></Mensaje>}
                    {showDetalleArticulo?.visible &&
                        <DetalleArticulo
                            visible={showDetalleArticulo.visible}
                            codArticulo={showDetalleArticulo.codArticulo}
                            onHandleShow={() => setShowDetalleArticulo({ ...showDetalleArticulo, visible: false })}
                        />
                    }
                </div>
            </div>
            {/* <!-- /breadcrumb --> */}
            <div className="panel panel-primary tabs-style-2">
                <div className=" tab-menu-heading">
                    <div className="tabs-menu1">
                        <Card>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <TablaDataBasica
                                                columns={errorPedidoColumnas}
                                                data={errorPedidoLista}
                                                mostrarCantidadRgistros={true}
                                                mostrarFiltro={true}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default ErrorPedidos;

