import { useSelector } from "react-redux";

export const MENUITEMS = (rubros:any) : {}[] => [
  //inicio
  {
    menutitle: "",
    Items: [
      {
        title: "Inicio",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="side-menu__icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" />
          </svg>
        ),
        type: "link",
        selected: false,
        active: false,
        path: ''
      },
    ],
  },
   //Rubros/Catalogo
   categorias(rubros), 
   {
    menutitle: "",
    Items: [
      {
        title: "Marcas",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="side-menu__icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            >
            <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" />
          </svg>
        ),
        type: "link",
        selected: false,
        active: false,
        path: '/marcalistado'
      },
    ],
  },//Novedades
  {
    menutitle: "",
    Items: [
      {
        title: "Novedades",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="side-menu__icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" />
          </svg>
        ),
        type: "link",
        selected: false,
        active: false,
        path: '/novedades'
      },
    ],
  },
 
  
  // {
  //   menutitle: "",
  //   Items: [
  //     {
  //       title: "Sobre nosotros",
  //       icon: (
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           className="side-menu__icon"
  //           width="24"
  //           height="24"
  //           viewBox="0 0 24 24"
  //         >
  //           <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" />
  //         </svg>
  //       ),
  //       type: "link",
  //       selected: false,
  //       active: false,
  //       path: '/datosempresa'
  //     },
  //   ],
  // },
];


function categorias(rubros:any) {
  const rubrosComponent = listaRubros(rubros);
  return (
    //Articulos
    {
      menutitle: "",
      Items: [
        {
          title: "Catalogo",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="side-menu__icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M22 7.999a1 1 0 0 0-.516-.874l-9.022-5a1.003 1.003 0 0 0-.968 0l-8.978 4.96a1 1 0 0 0-.003 1.748l9.022 5.04a.995.995 0 0 0 .973.001l8.978-5A1 1 0 0 0 22 7.999zm-9.977 3.855L5.06 7.965l6.917-3.822 6.964 3.859-6.918 3.852z" />
              <path d="M20.515 11.126 12 15.856l-8.515-4.73-.971 1.748 9 5a1 1 0 0 0 .971 0l9-5-.97-1.748z" />
              <path d="M20.515 15.126 12 19.856l-8.515-4.73-.971 1.748 9 5a1 1 0 0 0 .971 0l9-5-.97-1.748z" />
            </svg>
          ),
          active: false,
          type: "sub",
          selected: false,
          children: rubrosComponent,
          
        },
      ],
    }
  )
}


function listaRubros(rubros: any) {
  var listado: any[] = [];
  rubros.map((rubro: any) => {
    listado.push({
      title: rubro.detalle,
      path: `/catalogorubro?rubroId=${rubro.id}`,
      type: "link",
      active: false,
      selected: false,
      //children: listaLinea(rubro.articuloRubroLineaList),
      key: rubro.id
    });
  });

  return listado;
}


function listaLinea(lineas: any) {
  var listado: any[] = [];
  lineas.map((linea: any) => {
    listado.push({
      path: `/catalogolistado?lineaId=${linea.id}`,
      title: linea.detalle,
      type: "link",
      active: false,
      selected: false,
      key: linea.id
    });
  });

  return listado;
}

