import React, { useEffect, useRef, useState } from "react";
import { Navbar, Dropdown, Button, Form, Col, Row, Modal, Card, Spinner } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import { Link, useParams, useNavigate, NavLink, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { consultaArticuloHeader } from "../../../redux/actions/grid";
import { formatearNumero } from "../../../functions/Procedimientos";

import apiFc from "../../../services/APIFc";
import { setPedidosDespachados } from "../../../redux/actions/pedido";
import * as Switcherdata from './ExternalSwitcherheader';
import { decryptData } from "../../../functions/cryptoUtils";
import BarraBusquedaSugerida, { respuestaBusqueda } from "../../../components/BarraBusqueda/BarraBusquedaSugerida";


export default function ExternalHeader() {
  const [Lang, setLang] = React.useState(false);
  const [fullscreens, setFullscreen] = React.useState(true);
  const [items, setItems] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [queryParameters] = useSearchParams();
  const  user = useSelector((store: any) => decryptData(store.authreducer.user));
  const { pedidoActualRenglones, pedidosDespachados } = useSelector((store: any) => store.pedidoreducer);
  const novedades = useSelector((store: any) => decryptData(store.principalreducer.novedades) || []);
  const inputRef = useRef<HTMLInputElement | null>(null);


  // FullScreen
  var elem: any = document.documentElement;
  const Fullscreen: any = (vale: any) => {
    switch (vale) {
      case true:
        elem.requestFullscreen();
        setFullscreen(false)
        break;
      case false:
        document.exitFullscreen()
        setFullscreen(true)
        break;
    }
  }


  //leftsidemenu
  const openCloseSidebar = () => {
    document.querySelector("body")?.classList.toggle("sidenav-toggled");
  };
  //rightsidebar
  const Rightsidebar = () => {
    document.querySelector(".sidebar-right")?.classList.add("sidebar-open");
  };
  const Darkmode = () => {
    document.querySelector(".app")?.classList.toggle("dark-theme");
    document.querySelector(".app")?.classList.remove("light-theme");
  };

  // responsivesearch
  const responsivesearch = () => {
    document.querySelector(".navbar-form")?.classList.toggle("active");
    if (inputRef !== null) {
      inputRef?.current?.focus();
    }
  };
  //swichermainright
  const swichermainright = () => {
    document.querySelector(".demo_changer")?.classList.toggle("active");
    let rightSidebar: any = document.querySelector(".demo_changer")
    rightSidebar.style.right = "0px";
  };
  const [price, setPrice] = React.useState(0);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open: any = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };


  const [Data, setData] = React.useState([]);

  const { id } = useParams();

  React.useEffect(() => {
  })

  let navigate = useNavigate();

  const routeChange = () => {
    let path = `${process.env.PUBLIC_URL}/login`;
    navigate(path);
  }
  const signOut = () => {

  }

  function InicialesCliente(): string {
    const arrayString = user.clienteRazonSocial.split(' ');
    return " " + (arrayString[0].charAt(0)) + (arrayString[1].charAt(0))
  }

  useEffect(() => {
  }, []);

  useEffect(() => {
  }, [novedades]);

  function handleRecibirPedido(pedidoNroMovVta: any): void {
    apiFc.post(`/web/pedido/pedidos/recibir?nmv=${pedidoNroMovVta}`, `{}`)
      .then(res => {
        apiFc.get(`/web/pedido/pedidos/despachados`)
          .then(res => {
            dispatch(setPedidosDespachados(res.data))
          }).catch(err => {
            dispatch(setPedidosDespachados([]))
          })
      }).catch(res => {

      });
  }

  //funcion para manejar el buscador delinput
  const handleSearchInput = (event: any) => {
    if (event.keyCode === 13) {
      if (!isNaN(event.target.value as any)) {
        dispatch(consultaArticuloHeader(event.target.value))
      } else if (event.target.value === 'test123') {
        navigate(`${process.env.PUBLIC_URL}/test`)
      } else {
        navigate(`/catalogolistado?filtro=${event.target.value}`)
      }

      event.target.value = ""
    }
  };

  return (
    <Navbar className="main-header side-header sticky nav nav-item">
      <div className="main-container container-fluid">
        <div className="main-header-left main-header-left-external wd-20p">
          <div className="responsive-logo">
            {/* <Link to={`${process.env.PUBLIC_URL}/`} className="header-logo">
              <img
                src={require("../../../assets/img/logos/logo_favicon.png")}
                className="mobile-logo logo-1"
                alt="logo"
              />
              <img
                src={require("../../../assets/img/logos/logo_favicon_w.png")}
                className="mobile-logo dark-logo-1"
                alt="logo"
              />
            </Link> */}
          </div>
          <div className="logo-horizontal">
            <Link to={`${process.env.PUBLIC_URL}/`} className="header-logo">
              <img
                src={require("../../../assets/img/logos/logo_masterpage.png")}
                className="mobile-logo logo-1"
                alt="logo"
              />
              <img
                src={require("../../../assets/img/logos/logo_masterpage_w.png")}
                className="mobile-logo dark-logo-1"
                alt="logo"
              />
            </Link>
          </div>
          <div
            className="app-sidebar__toggle"
            data-bs-toggle="sidebar"
            onClick={() => openCloseSidebar()}
          >
            <div className="open-toggle cursor-pointer">
              <i className="header-icon fe fe-align-left"></i>
            </div>
            <Link className="close-toggle" to="#">
              <i className="header-icon fe fe-x"></i>
            </Link>
          </div>
          
        </div>
         {/* <div className="main-header-center ms-4 d-sm-none d-md-none d-lg-block form-group">
            <input
              className="form-control"
              placeholder="Buscador..."
              type="search"
              onKeyDown={(e) => handleSearchInput(e)}
            />
            <Button variant="" className="btn">
              <i className="fas fa-search"></i>
            </Button>
          </div> */}
        {/* <div className="ms-4 wd-50p justify-content-center d-flex">
            <Col xs={12} sm={8} md={6} lg={6}>
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Buscador..."
                  type="search"
                  onKeyDown={(e) => handleSearchInput(e)}
                />
                <Button variant="" className="btn">
                  <i className="fas fa-search"></i>
                </Button>
              </div>
            </Col>
          </div> */}
          <div className="main-header-center ms-4 d-sm-none d-md-none d-lg-block form-group">
            <BarraBusquedaSugerida
              classNameContainer="main-header-center ms-4 d-sm-none d-md-none d-lg-block form-group"
              classNameInput="form-control"
              onSearch={(busqueda: respuestaBusqueda) => {
                if (busqueda.type === 1) {
                  if (!isNaN(busqueda.value as any)) {
                    dispatch(consultaArticuloHeader(busqueda.value))
                  } else {
                    navigate(`/catalogolistado?filtro=${busqueda.value}`)
                  }
                } else if (busqueda.type === 2) {
                  navigate(`/catalogolistado?filtro=${busqueda.text}`);
                }
              }} />
          </div>
        <div className="main-header-right">
          <Navbar.Toggle
            className="navresponsive-toggler d-lg-none ms-auto"
            type="button"
          >
            <span className="navbar-toggler-icon fe fe-more-vertical"></span>
          </Navbar.Toggle>
          <div className="mb-0 navbar navbar-expand-lg   navbar-nav-right responsive-navbar navbar-dark p-0">
            <Navbar.Collapse className="collapse" id="navbarSupportedContent-4">
              <ul className="nav nav-item header-icons navbar-nav-right dark-mode-right align-items-center ">
              <li className="dropdown nav-item ">
                  <Button className="btn btn-primary" type="button" onClick={() => { signOut(); routeChange() }} >
                    Iniciar Sesion
                  </Button>
                </li>
                <li className="dropdown nav-item">
                  <Link
                    to="#"
                    className="new nav-link theme-layout nav-link-bg layout-setting"
                    onClick={() => Darkmode()}
                  >
                    <span className="dark-layout">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-icon-svgs"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20.742 13.045a8.088 8.088 0 0 1-2.077.271c-2.135 0-4.14-.83-5.646-2.336a8.025 8.025 0 0 1-2.064-7.723A1 1 0 0 0 9.73 2.034a10.014 10.014 0 0 0-4.489 2.582c-3.898 3.898-3.898 10.243 0 14.143a9.937 9.937 0 0 0 7.072 2.93 9.93 9.93 0 0 0 7.07-2.929 10.007 10.007 0 0 0 2.583-4.491 1.001 1.001 0 0 0-1.224-1.224zm-2.772 4.301a7.947 7.947 0 0 1-5.656 2.343 7.953 7.953 0 0 1-5.658-2.344c-3.118-3.119-3.118-8.195 0-11.314a7.923 7.923 0 0 1 2.06-1.483 10.027 10.027 0 0 0 2.89 7.848 9.972 9.972 0 0 0 7.848 2.891 8.036 8.036 0 0 1-1.484 2.059z" />
                      </svg>
                    </span>
                    <span className="light-layout">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-icon-svgs"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M6.993 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007S14.761 6.993 12 6.993 6.993 9.239 6.993 12zM12 8.993c1.658 0 3.007 1.349 3.007 3.007S13.658 15.007 12 15.007 8.993 13.658 8.993 12 10.342 8.993 12 8.993zM10.998 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2h-3zm17 0h3v2h-3zM4.219 18.363l2.12-2.122 1.415 1.414-2.12 2.122zM16.24 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.342 7.759 4.22 5.637l1.415-1.414 2.12 2.122zm13.434 10.605-1.414 1.414-2.122-2.122 1.414-1.414z" />
                      </svg>
                    </span>
                  </Link>
                </li>
                {/* Inicio pedidos despachados */}
                <Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">
                  <Dropdown.Toggle
                    className="new nav-link profile-user d-flex"
                    variant=""
                  >
                    {/*<div className="profile-dot">
                      <figcaption>{InicialesCliente()}</figcaption>
                    </div>*/}
                    <img
                      alt=""
                      src={require("../../../assets/img/faces/2.jpg")}
                      className=""
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="dropdown-item"
                      to="#"
                      onClick={() => {
                        Switcherdata.horizontal();
                        Switcherdata.Scrollable();
                      }}
                    >
                      <i className="far fa-sun"></i> Menu Horizontal
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </Navbar.Collapse >
          </div >
        </div >
      </div >
    </Navbar >
  );
}

ExternalHeader.propTypes = {};

ExternalHeader.defaultProps = {};
