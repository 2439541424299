import React from "react";
import { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import apiFc from "../../services/APIFc";
import { StockLevel, formatearPrecio, getProductBadgeInfo, isUserLogued } from "../../functions/Procedimientos";
import InputCarritoCompra from "./InputCarritoCompra";
import { AlertDialogInterface } from "./Interfaces";
import { useSelector } from "react-redux";
import { decryptData } from "../../functions/cryptoUtils";

export default function DetalleArticulo(props: any) {
    const user = useSelector((store: any) => decryptData(store.authreducer.user));
    const [showDetalleArticulo, setShowDetalleArticulo] = useState<boolean>(props.visible);
    const [cargandoDetalleArticulo, setCargandoDetalleArticulo] = useState(0); //0:cargando 1:Cargado 2:Articulo inexistente
    const [detalleArticulo, setDetalleArticulo] = useState<any>({});
    //Administrador de las tabs
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
        id: "1",
        visible: false,
        severity: 'success', // severity="error" / "warning" / "info" / "success"
        timeOut: 2000, // severity="error" / "warning" / "info" / "success"
        cabecera: "Agregado!",
        texto: "el producto fue agregado.",
    });
    useEffect(() => {
    }, [alertDialog]);


    useEffect(() => {
        if (showDetalleArticulo) {

            setFullscreen("true")
            setCargandoDetalleArticulo(0)
            apiFc.get(`/web/articulos/consulta/header/${props.codArticulo}`).then(res => {
                setDetalleArticulo(res.data);
                setCargandoDetalleArticulo(1)
            }).catch(error => {
                setDetalleArticulo(null);
                setCargandoDetalleArticulo(2)
            })
        } else {
            props.onHandleShow();
        }
    }, [showDetalleArticulo])

    let [fullScreen, setFullscreen] = useState("true");

    useEffect(() => {

    }, [fullScreen])

    const fullscreenmodalClose = () => setShowDetalleArticulo(false);

    function handleShow(breakpoint: any) {
        setFullscreen(breakpoint);
        setShowDetalleArticulo(true);
    }

    function Mensaje() {
        const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
            props,
            ref,
        ) {
            return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
        });

        const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setAlertDialog({ ...alertDialog, visible: false });
        };
        return (
            <Snackbar
                open={alertDialog.visible}
                autoHideDuration={alertDialog.timeOut}
                onClose={handleClose}
                //Aca decido si es mobile muestro arriba el cartelito, sino abajo
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose}
                    severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
                    sx={{ width: '100%' }}>
                    <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
                </Alert>
            </Snackbar>
        )
    }
    const { badgeBg, badgeText, badgeClassName } = getProductBadgeInfo(detalleArticulo);

    function articuloRubro(): string {
        return detalleArticulo?.articuloRubro?.detalle || "Sin rubro"
    };

    function articuloLinea(): string {
        return detalleArticulo?.articuloRubro?.articuloRubroLineaList[0]?.detalle || "Sin linea"
    };

    function renderCuerpo(): any {
        if (cargandoDetalleArticulo == 0)
            return (
                <div className="text-center mg-b-20 ">
                    <Spinner
                        animation="border"
                        className="spinner-border "
                        role="status"
                    >
                        <span className="sr-only">Cargando...</span>
                    </Spinner>
                </div>
            );
        if (cargandoDetalleArticulo == 1)
            return (
                <>

                    {alertDialog.visible && <Mensaje ></Mensaje>}
                    <Row className="row row-sm" >
                        <Col >
                            <Card>
                                <Card.Body>
                                    <Row className="row row-sm ">
                                        <Col>
                                            <Row >
                                                <Col xxl={6} xl={6} lg={10} md={10} sm={10} className=" wd-100p m-auto">
                                                    <div className="product-carousel  border br-5 ">
                                                        <div id="Slider" className="carousel slide">
                                                            <img src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/${detalleArticulo.articuloImagenGXI}`}
                                                                key={detalleArticulo.articuloCodigo}
                                                                alt=""
                                                                className="img-fluid mx-auto d-block"
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src = "https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png";
                                                                }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xxl={6} lg={6} md={12} className="details mt-4 mt-xl-0 mt-2">
                                            <h4 className="product-title mb-1">
                                                {detalleArticulo.articuloDescripcion}
                                            </h4>
                                            <div className="category">
                                                <><strong>{articuloRubro()}</strong></>
                                            </div>
                                            <div className="line">
                                                <>{articuloLinea()}</>
                                            </div>
                                            <div className="line">
                                                <label>Articulo codigo: <>{detalleArticulo.articuloCodigo}</></label>
                                            </div>
                                            <div className="line">
                                                <dt>
                                                    <Badge
                                                        bg={badgeBg}
                                                        className={badgeClassName}>
                                                        {badgeText}
                                                    </Badge>
                                                </dt>
                                            </div>
                                            {/* <div className="rating mb-1">
                                                <div >
                                                
                                                </div>
                                            </div> */}
                                            {isUserLogued() ? (
                                                <Table className="table table-bordered mb-0 text-nowrap">
                                                    <thead>
                                                        <tr>
                                                            {user.clienteConfiguracionMostrarCos && <th>Costo</th>}
                                                            <th>Sug1</th>
                                                            <th>Sug2</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {user.clienteConfiguracionMostrarCos && (
                                                                <td className="paso5">
                                                                    {formatearPrecio({ valor: parseFloat(detalleArticulo.articuloPrecio) })}
                                                                </td>
                                                            )}
                                                            <td className="paso6">
                                                                {formatearPrecio({ valor: parseFloat(user.clienteConfiguracionMostrarIVA ? detalleArticulo.articuloPrecioVentaSug1 * (1 + detalleArticulo.articuloTasaIVA / 100) : detalleArticulo.articuloPrecioVentaSug1) })}
                                                            </td>
                                                            <td className="paso7">
                                                                {formatearPrecio({ valor: parseFloat(user.clienteConfiguracionMostrarIVA ? detalleArticulo.articuloPrecioVentaSug2 * (1 + detalleArticulo.articuloTasaIVA / 100) : detalleArticulo.articuloPrecioVentaSug2) })}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="flex-row justify-content-center">
                                                                Stock: <StockLevel stock={detalleArticulo.articuloStock} stockMin={detalleArticulo.articuloStockMin} stockMax={detalleArticulo.articuloStockMax} />
                                                            </th>
                                                            <th className="flex-row justify-content-center" colSpan={user.clienteConfiguracionMostrarCos ? 2 : 3}>
                                                                <InputCarritoCompra
                                                                    articulo={detalleArticulo}
                                                                    origen="detalleArticulo"
                                                                    onEvent={(e) => setAlertDialog(e)}
                                                                />
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </Table>)
                                                : <></>
                                            }

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </>
            );
        if (cargandoDetalleArticulo == 2)
            return (
                <Alert
                    className={`alert mb-2 alert-danger`}
                    variant=""
                >
                    <Alert.Heading>
                        <h4 className="alert-heading">
                            <i className={`fe fe-alert-octagon me-2 tx-20`}></i>
                            Error cargando articulo
                        </h4>
                    </Alert.Heading>
                    <p>
                        Sucedio un error cargando la informacion del articulo
                    </p>
                    <hr />
                    <p className="mb-0">
                        Puede reintentar con otro codigo.
                    </p>
                </Alert>
            );

    }


    return (
        <Modal className="wd-80p d-flex m-auto" dialogClassName="modal-90w" show={showDetalleArticulo} onHide={() => setShowDetalleArticulo(false)}>
            <Modal.Header>
                <Modal.Title>Detalle articulo</Modal.Title>
                <span className="d-flex ms-auto" onClick={() => setShowDetalleArticulo(false)}><i className='fe fe-x ms-auto' ></i></span>
            </Modal.Header>
            <Modal.Body>
                {renderCuerpo()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDetalleArticulo(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}