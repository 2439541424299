import { Dispatch } from "react"
import { useDispatch } from "react-redux";
import APIFc from "../../services/APIFc";
import { AnyAction } from "redux";
import apiFc from "../../services/APIFc";
import { decryptData, encryptData } from "../../functions/cryptoUtils";

export const logOut = (texto: string) => {
    return {
        type: "LOGOUT",
        payload: encryptData(texto)
    }
}

export const setUserInfo = (user: any) => {
    return {
        type: "USER_SET_INFO",
        payload: encryptData(user)
    }
}

export const logIn = (token: string) => {

    return {
        type: "LOGIN_SUCCESS",
        payload: encryptData(token)
    }
}
export const setLoginText = (text: string) => {

    return {
        type: "SET_LOGIN_TEXT",
        payload: encryptData(text)
    }
}

export const setVersion = (version: number) => {
    return {
        type: "SET_VERSION",
        payload: encryptData(version)
    }
}


export const fetchfetchUserInfo = () => {
    return (dispatch: Dispatch<any>) => {
        apiFc.get(`web/cliente`)
            .then(res => {
                dispatch(setUserInfo(res.data))
            }).catch(err => {

            });
    };
};