import React from 'react';
import { useNavigate } from 'react-router-dom';
import LazyImage from '../../functions/LazyImage';
import "./cardlinea.css";

interface CardLineaProps {
    onClick?: () => void;
    rubro?: any;
    linea: any;
    showRubroName?: boolean;
    showIcon?: boolean; // Nueva propiedad opcional
}

const CardLinea = (props: CardLineaProps) => {
    const navigate = useNavigate();
    const customOnClick = props.onClick == undefined
        ? (() => {
            if (props.rubro) {
                navigate((window.location.href.includes("/principal")) 
                    ? `/principal/catalogorubro?rubroId=${props.rubro.id}&lineaId=${props.linea.id}`
                    : `/catalogorubro?rubroId=${props.rubro.id}&lineaId=${props.linea.id}`
                );
            }
        })
        : props.onClick;

    const LineaImagen = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Lineas/${props.linea.id}_${props.rubro?.id}.jpg`;
    const RubroIcono = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Rubros/Iconos/${props.rubro?.id}.svg`;

    return (
        <div className="box" onClick={customOnClick}>
            <LazyImage
                className="section-img"
                key={props.linea.id}
                src={LineaImagen}
                alt="Imagen 1"
                onError={(e: any) => {
                    e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                }} />
            <div className="content-center">
                
                    <div className="icon-container">
                    {props.showIcon && ( // Condición para mostrar u ocultar el contenedor del ícono
                        <LazyImage
                            className="icon-img"
                            key={props.linea.id}
                            src={RubroIcono}
                            alt="Imagen 1"
                            onError={(e: any) => {
                                e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                            }} /> )}
                    </div>
               
                <div className="text-container">
                    {props.showRubroName && props.rubro?.detalle && <p className="special-text">{props.rubro.detalle}</p>}
                    <strong><p className="special-text">{props.linea.detalle}</p></strong>
                </div>
            </div>
        </div>
    );
}

export default CardLinea;


/*Viejo estilo usado hasta el cambio de imagenes*/ 
// import React, { CSSProperties, useEffect, useState } from 'react'
// import { Col } from 'react-bootstrap'
// import "./cardlinea.css"
// // import Color, { useColor } from 'color-thief-react';
// // import { ArrayRGB } from 'color-thief-react/lib/types/ArrayRGB';
// import { useNavigate } from 'react-router-dom';
// import LazyImage from '../../functions/LazyImage';

// interface CardLineaProps {
//     onClick?: () => void; // Define the onClick prop as a function
//     rubro: any;
//     linea: any;
// }

// const CardLinea = (props: CardLineaProps) => {
//     const navigate = useNavigate();
//     //Si el usuario no me envio un onClick Especifico, yo voy a poner por default el de nvagate tu cataloDetalle
//     const customOnClick = props.onClick == undefined
//         ? (() => navigate((window.location.href.includes("/principal")) ? `/principal/catalogorubro?rubroId=${props.rubro.id}&lineaId=${props.linea.id}` : `/catalogorubro?rubroId=${props.rubro.id}&lineaId=${props.linea.id}`))
//         : props.onClick

//     const LineaImagen = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Lineas/${props.linea.id}_${props.rubro.id}.jpg`;
//     const RubroIcono = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Rubros/Iconos/${props.rubro.id}.svg`;
//     // const LineaImagen = `../../../../img/Imagenes/Lineas/prueba2.jpeg`;
//     // const gradientStyle = (color: ArrayRGB | undefined): CSSProperties => {
//     //     if (color === undefined) {
//     //         color = [0, 0, 0]
//     //     }
//     //     return { background: `linear-gradient(to top, rgba(${color[0]}, ${color[1]}, ${color[2]}, 1), rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.8), rgba(${color[0]}, ${color[1]}, ${color[2]}, 0))` }
//     // };


//     return (
//         // <>{(props.rubro === undefined)
//         //     ? <></>
//         //     : <Color src={LineaImagen} format="rgbArray" quality={1} >
//         //         {({ data, loading, error }) => (
//                               // data no recibe nada, esta undefined, por ende va a tomar el if (color === undefined) {color = [0, 0, 0]}
//                     <div className="box " onClick={customOnClick}>
//                         <LazyImage
//                             className="section-img"
//                             key={props.linea.id}
//                             src={LineaImagen}
//                             alt="Imagen 1"
//                             onError={(e: any) => {
//                                 e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
//                             }} />
//                         <div >
//                             <div className="half-width-container ">
//                                 <div className="half-width"
//                                 // style={gradientStyle(data)}
//                                 >
//                                     <div className="container-icono">
//                                         <LazyImage
//                                             className="half-width-img"
//                                             key={props.linea.id}
//                                             src={RubroIcono}
//                                             alt="Imagen 1"
//                                             onError={(e: any) => {
//                                                 e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
//                                             }} />
//                                         {/* <img src={`../../../../img/Imagenes/Rubros/Iconos/${props.linea.id}.svg`} alt="logo.png" className="half-width-img" /> */}
//                                     </div>
//                                     <div className="text-style-container">
//                                         <strong><p className="text-style d-flex">{props.linea.detalle}</p></strong>
//                                         <p className="text-style-2 d-flex">{props.rubro.detalle}</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 )}
//         //     </Color>
//         // }</>

// export default CardLinea