import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Col, Form, Row, InputGroup, Spinner } from "react-bootstrap";
import apiFc from "../../../../services/APIFc";
import MultiSelect from "../../../common/MultiSelect";
import { filterProduct } from "../../../../redux/actions/grid";
import { setPedidoActualRenglones } from "../../../../redux/actions/pedido";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from "react";
import { isMobile } from 'react-device-detect';
import ReactFacebookPixel from 'react-facebook-pixel';
import DatePicker from "react-datepicker";
import InputCarritoCompra from "../../../common/InputCarritoCompra";
import { AlertDialogInterface } from "../../../common/Interfaces";

const ArticuloNovedades = () => {
  const [articuloNovedad, setArticuloNovedad] = useState([]);
  const [filtroLocal, setFiltroLocal] = useState<string>('');
  useEffect(() => {
    try {
      setAlertDialog({ ...alertDialog, visible: false })
      window.scrollTo(0, 0)
      let date = new Date()
      date.setDate(date.getDate() - 30);
      apiFc.get(`/web/articulos/consulta/specification?filter=&dateFrom=${date.toISOString().split('T')[0].replaceAll('-', '/')}`).then(res => {
        setArticuloNovedad(res.data);
      })
    } catch (error) {
    }
  }, [])

  useEffect(() => {}, [articuloNovedad, filtroLocal]);

  const handleInput = (event: any) => {
    setFiltroLocal(event.target.value.toLowerCase());
  };

  //Administrador de las tabs
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  function Mensaje(props: any) {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref,
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertDialog({ ...alertDialog, visible: false });
    };
    return (
      <Snackbar
        open={alertDialog.visible}
        autoHideDuration={alertDialog.timeOut}
        onClose={handleClose}
        //Aca decido si es mobile muestro arriba el cartelito, sino abajo
        anchorOrigin={(isMobile) ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose}
          severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
          sx={{ width: '100%' }}>
          <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
        </Alert>
      </Snackbar>
    )
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Nuevos articulos</span>
          {alertDialog.visible && <Mensaje ></Mensaje>}
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      {/* <!-- row --> */}

      <Col xl={12} lg={12} md={12} className="mb-md-0 mb-3">
        <Card>
          <Card.Body className="p-2">
            <div className="input-group">
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Buscar ..."
                onChange={handleInput}
              />
              <span className="input-group-append">
                <Button variant="" className="btn btn-primary" type="button">
                  Buscar
                </Button>
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col xl={12} lg={12} md={12}>
        <Row className="row-sm">
          {(articuloNovedad.length == 0)
            ?
            <div className="text-center mg-b-20 ">
              <Spinner
                animation="border"
                className="spinner-border "
                role="status"
              >
                <span className="sr-only">Cargando...</span>
              </Spinner>
            </div>
            :
            articuloNovedad.filter((articuloFilter: any) =>
              articuloFilter?.articuloDescripcion.toLowerCase().search(filtroLocal) !== -1
            ).map((articulo: any) => (
              <Col sm={3} xl={3} xxl={3} lg={3} md={3} key={articulo.articuloCodigo}>
                <Card>
                  <Card.Body className="h-100  product-grid6">
                    <div className="pro-img-box product-image" >
                      <div onClick={() => {
                      }}>
                        {(articulo?.articuloImagenGXI !== null) ?
                          <>
                            <img className=" pic-1" src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/${articulo?.articuloImagenGXI}`} alt="product"
                              onError={(e: any) => {
                                e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                              }} />
                            <img className="pic-2" src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/${articulo?.articuloImagenGXI}`} alt="product"
                              onError={(e: any) => {
                                e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                              }} />
                          </>
                          :
                          (articulo?.articuloGrupoId !== null && articulo?.articuloGrupoId?.articuloImagenGXI !== null) ?
                            <>
                              <img className=" pic-1" src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/${articulo?.articuloGrupoId?.articuloGrupoImagenGXI}`} alt="product"
                                onError={(e: any) => {
                                  e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                                }} />
                              <img className="pic-2" src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/${articulo?.articuloGrupoId?.articuloGrupoImagenGXI}`} alt="product"
                                onError={(e: any) => {
                                  e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                                }} />
                            </>
                            :
                            <>
                              <img className=" pic-1" src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`} alt="product" />
                              <img className="pic-2" src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`} alt="product" />
                            </>
                        }
                      </div>
                      <ul className={"icons"}>
                        <li>
                          <div className="btn btn-primary"  >
                            <i className="fa fa-heart"></i>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="text-center pt-2">
                      <h4 className="h6 mb-2 mt-4 font-weight-bold text-uppercase">
                        {articulo.articuloCodigo}
                      </h4>
                      <h3 className="h6 ht-50 mb-2 mt-4 font-weight-bold text-uppercase">
                        {articulo.articuloDescripcion}
                      </h3>
                      <h4 className="h5 mb-0 mt-1 text-center font-weight-bold  tx-22">
                        {articulo.articuloUnidadVenta}{':       $'}{articulo.articuloPrecio}{" "}
                      </h4>
                      <InputCarritoCompra 
                        articulo={articulo}
                        origen="ArticuloNovedad"
                        onEvent={(e: any) => setAlertDialog(e)} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          }
        </Row>
      </Col>

      {/* <!-- row closed --> */}
    </div >
  );
}



export default ArticuloNovedades