import {Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import apiFc from "../../services/APIFc";
import FcCarousel from "../FcCarousel/FcCarousel";
import CardArticulo from "../Card Inicio/CardArticulo";


export default function ProductosRecomendadosInicio() {
    const [grupoSugerencias, setSugerencias] = useState([])

    useEffect(() => {
    
        buscarSugerenciaRubroArticuloGrupos();
    
    }, []);
    

    function buscarSugerenciaRubroArticuloGrupos() {
        apiFc.get(`/web/principal/producto/sugerencias`).then(res => {
            setSugerencias(res.data);
      
          })
      }
   
  
  return (
    <div >
          <div className='mg-b-10 mg-t-30'>
        <h1 className=" display-6 tx-20 tx-bold"><strong>Productos recomendados</strong></h1>
      </div>
      <Row >
        <FcCarousel items={grupoSugerencias?.map((item: any, i: number) => {
          return (
            (i < 13) ? (
              <Col xs={6} sm={3} xl={2} xxl={2} lg={3} md={3} key={item.articuloCodigo}>
                <CardArticulo item={item} mostrarInputCarrito={false} />
              </Col>
            ) : []
          );
        })} />
      </Row>
    </div>
  )
}

