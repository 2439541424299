import { combineReducers } from 'redux';
import { cartreducer } from './reducer';
import { authreducer } from './auth';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist' 
import { gridreducer } from './grid';
import { resumenreducer } from './resumen';
import { pedidoreducer } from './pedido';
import { commonreducer } from './common';
import { principalreducer } from './principal';



const persistConfig = {
    key: 'root',
    storage,
    // whitelist: [] Aqui iran los datos que reciba de la api que quiero almacenar
}


const rootred = combineReducers({
    cartreducer,
    authreducer,
    gridreducer,
    pedidoreducer,
    commonreducer,
    principalreducer,
    resumenreducer
});

const persistedReducer = persistReducer(persistConfig, rootred)

export default persistedReducer;
