import { useEffect, useState } from "react";
import { Button, Card, Tab, Table, Tabs } from "react-bootstrap";
import apiFc from "../../../../services/APIFc";
import CartelInformativo from "../../../common/CartelInformativo";
import DetallePedido from "./DetallePedido";
import DetalleError from "../../../common/DetalleError";
import { useSelector } from "react-redux";
import SeguimientoError from "../../../common/SeguimientoError";
import { decryptData } from "../../../../functions/cryptoUtils";

const HistoricoPedido = () => {
  const [tabKey, setTabKey] = useState('tPedidos');

  //Variable donde se almacenan los pedidos
  const [pedidos, setPedidos] = useState<any[]>([]);

  const transportesDisponibles = useSelector((store: any) => decryptData(store.pedidoreducer.transportesDisponibles));
  //Funcion debe ser cambiada por axios y reduc o BackEnd
  function imagenEstadoPedido(idEstado: number): string {

    switch (idEstado) {
      case 1:
        return ("ENVIADO.png");
      case 2:
        return ("RECIBIDO.png");
      case 3:
        return ("DEMORADO.png");
      case 4:
        return ("PROCESANDO.png");
      case 5:
        return ("EMBALADO.png");
      case 6:
        return ("FACTURADO.png");
      case 10:
        return ("DESPACHADO.png");
      case 20 || 21 || 22 || -70 || -101 || -102:
        return ("CANCELADO.png");
      case 50:
        return ("ENTREGADO.png"); //<a href="https://www.flaticon.es/iconos-gratis/entrega" title="entrega iconos">Entrega iconos creados por Freepik - Flaticon</a>

      default: return ("PROCESANDO.png");
    }
  }

  //Funcion debe ser cambiada por axios y reduc o BackEnd
  function tipoPedido(idEstado: number): string {
    switch (idEstado) {
      case 7:
        return ("Directo");
      case 10:
        return ("Telefonico");
      case 11:
        return ("Recambio");
      case 12:
        return ("Obra");
      case 13:
        return ("Local");
      case 15:
        return ("Acopio");
      case 16:
        return ("Vendedor");
      case 17:
        return ("Pendiente");
      case 18:
        return ("Web");
      case 19:
        return ("Util");

      default: return ("");
    }
  }

  function obtenerPedidos(): void {
    apiFc.get(`/web/pedido/pedidos/historico?page=0&rows=10`).then(res => {
      setPedidos(res.data.content);
      // console.log(res.data.content)
    }).catch(error => {
      CartelInformativo("Error", "Hubo un error consultando al servidor")
    });
  }
  useEffect(() => {
    setPedidos([]);
    switch (tabKey) {
      case 'tPedidos':
        obtenerPedidos()
        break;
      case 'tDevoluciones':
        break;
    }
  }, [tabKey]);

  //a efectos plracticos de refrescar a pantalla
  useEffect(() => {
  }, [pedidos])

  function handleRecibirPedido(pedidoNroMovVta: any): void {
    apiFc.post(`/web/pedido/pedidos/recibir?nmv=${pedidoNroMovVta}`, `{}`)
      .then(res => {
        obtenerPedidos()
      }).catch(res => {
        CartelInformativo("Error", "Hubo un error consultando al servidor")
      });
  }

  function renderCuerpoTabla(): any {

    if (pedidos.length && pedidos.length !== 0) {
      return (
        pedidos.map((pedido: any, index: any) => {
          var transporteNombre = transportesDisponibles.find((transporte: any) => transporte.transporteID == pedido.transportePedidoID)
          return (
            <tr key={pedido.pedidoPK.pedidoID}>
              <th className="text-center" scope="row">{new Date(pedido.pedidoFecha).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              })}</th>
              <td className="text-center">{tipoPedido(pedido.pedidoTipoID)}</td>
              <td className="text-center">
                <img
                  src={require(`../../../../assets/img/pedido/${imagenEstadoPedido(pedido.pedidoEstado.pedidoEstadoId)}`)}
                  alt=""
                  width="32"
                  height="32"
                  className="br-2"
                />
              </td>
              <td className="text-center">{pedido.pedidoEstado.pedidoEstadoDescripcion}</td>
              <td className="text-center">{pedido.pedidoNroMovVta}</td>
              <td className="text-center">{transporteNombre ? transporteNombre.transporteNombre : pedido.transportePedidoID}</td>
              <td className="text-center">
                {pedido.pedidoFechaDespacho &&
                  new Date(pedido.pedidoFechaDespacho).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })}
              </td>
              <td className="text-center">
                <Button
                  variant=""
                  className="btn btn-primary"
                  type="button"
                  //onClick={(e) => { CartelInformativo("En desarrollo", "Modulo en desarrollo") }}
                  onClick={() => handleShowDetallePedido(pedido.pedidoNroMovVta)}
                >
                  <i className="fa fa-info"></i>
                </Button>

              </td>
              {(pedido.pedidoEstado.pedidoEstadoId == 50 && pedido.pedidoErrorList.length != 0)
                ?
                <td className="text-center">
                  <Button
                    variant=""
                    className="btn btn-primary"
                    type="button"
                    //onClick={(e) => { CartelInformativo("En desarrollo", "Modulo en desarrollo") }}
                    onClick={(e) => handleShowDSeguimientoError(pedido.pedidoNroMovVta, pedido.pedidoErrorList[0].pedidoErrorID)}
                  >
                    <p className="m-auto"> Seguimiento error</p>
                  </Button>
                </td>
                :
                (pedido.pedidoEstado.pedidoEstadoId == 10)
                  ?
                  <td className="text-center">
                    <Button
                      variant=""
                      className="btn btn-primary"
                      type="button"
                      //onClick={(e) => { CartelInformativo("En desarrollo", "Modulo en desarrollo") }}
                      onClick={(e) => {

                        handleRecibirPedido(pedido.pedidoNroMovVta)
                      }}
                    >
                      <p className="m-auto">Recibido</p>
                    </Button>
                  </td>
                  :
                  <></>
              }
            </tr>
          )
        })
      );
    } else {
      return (
        <div style={{ height: "500px", alignItems: "center", position: "relative" }}>
          <span style={{ position: "absolute", bottom: "50%", left: "50%", whiteSpace: "nowrap" }}>No hay resultados</span>
        </div>);
    }
  }

  const [showDetallePedido, setShowDetallePedido] = useState<{ visible: boolean, nroMovVenta: string, fullScreen: string }>();

  useEffect(() => {
  }, [showDetallePedido])

  function handleShowDetallePedido(nroMovVenta: string) {
    setShowDetallePedido({ ...showDetallePedido, visible: true, nroMovVenta: nroMovVenta, fullScreen: "true" })
  }

  const [showDetalleError, setShowDetalleError] = useState<{ visible: boolean, nroMovVenta: string, fullScreen: string }>();
  const [showSeguimientoError, setShowSeguimientoError] = useState<{ visible: boolean, nroMovVenta: string, fullScreen: string, errorId: number }>();

  useEffect(() => {

  }, [showDetalleError && showSeguimientoError])

  function handleShowDetalleError(nmv: string) {
    setShowDetalleError({ ...showDetalleError, visible: true, nroMovVenta: nmv, fullScreen: "true" });
  }



  function handleShowDSeguimientoError(nmv: string, paramErrorId: number) {
    setShowSeguimientoError({ ...showSeguimientoError, visible: true, nroMovVenta: nmv, fullScreen: "true", errorId: paramErrorId });
  }

  return (
    <div>
      {showSeguimientoError?.visible &&
        <SeguimientoError
          visible={showSeguimientoError.visible}
          nroMovVenta={showSeguimientoError.nroMovVenta}
          pedidoErrorId={showSeguimientoError.errorId}
          onHandleShow={() => setShowSeguimientoError({ ...showSeguimientoError, visible: false })}
        />
      }
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Historico pedido
          </span>
          {showDetallePedido?.visible &&
            <DetallePedido
              visible={showDetallePedido.visible}
              nroMovVenta={showDetallePedido.nroMovVenta}
              onHandleShow={() => setShowDetallePedido({ ...showDetallePedido, visible: false })}
            />
          }
          {showDetalleError?.visible &&
            <DetalleError
              visible={showDetalleError.visible}
              nroMovVenta={showDetalleError.nroMovVenta}
              onHandleShow={() => setShowDetalleError({ ...showDetalleError, visible: false })}
            />
          }

        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      <div className="panel panel-primary tabs-style-2">
        <div className=" tab-menu-heading">
          <div className="tabs-menu1">
            <Card>
              <Card.Body>
                {/* <!-- Tabs --> */}
                <Tabs
                  defaultActiveKey="tPedidos"
                  className=" panel-tabs main-nav-line "
                  onSelect={(k: any) => setTabKey(k)}
                >
                  <Tab eventKey="tPedidos" title="Pedidos">
                  </Tab>
                  <Tab eventKey="tDevoluciones" title="Devoluciones">
                  </Tab>
                </Tabs>
                <Card.Body>
                  <div className="table-responsive">
                    <Table className="table mg-b-0 text-md-wrap">
                      <thead>
                        <tr>
                          <th className="text-center">Fecha</th>
                          <th className="text-center">Tipo</th>
                          <th className="text-center">Estado</th>
                          <th className="text-center">Proceso</th>
                          <th className="text-center">NroMovVenta</th>
                          <th className="text-center">Transporte</th>
                          <th className="text-center">Despacho</th>
                          <th className="text-center">Mas info</th>
                          <th className="text-center">Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderCuerpoTabla()}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}


export default HistoricoPedido;
