import axios from 'axios';
import { logOut } from '../redux/actions/auth';
import { declareAxiosError } from '../redux/actions/common';
import store from '../redux/store/store';
import { isUserLogued } from '../functions/Procedimientos';
import { decryptData } from '../functions/cryptoUtils';

var apiFc = axios.create({
});
  apiFc.defaults.baseURL = `https://backend.universidadliberal.com.ar` //producto midddleware proxy
  //apiFc.defaults.baseURL = `http://app.ferreteracentral.com.ar:4051` // developer back 
  //apiFc.defaults.baseURL = `http://localhost:4050`
  apiFc.defaults.timeout = 1000000


  apiFc.interceptors.request.use(request => {
    console.log("hablando con " , apiFc.defaults.baseURL)
    //si el usuario no esta logueado, entonces no envio la cabeera con el Bearer token.
    if (isUserLogued()){
      request.headers['Authorization'] = 'Bearer ' + decryptData(store.getState().authreducer.token);
    }
    
    //si es un post, indico que en el contenido va un Json, por que es el standar que usamos
    if ( request.method == "post")
      request.headers['Content-Type'] = 'application/json';
    return request
  })

  apiFc.interceptors.response.use(response => {
    return response
  }, error => {
    if(error.response.status === 403) {
      store.dispatch(declareAxiosError(false));
      store.dispatch(logOut("Sesión expirada. Por favor, ingrese nuevamente"));
    } else {
      store.dispatch(declareAxiosError(true));
      throw error;
    }
  } )  
  
export default apiFc
