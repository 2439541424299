const INIT_STATE: any = {
    axiosError: true,  
};

export const commonreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'AXIOS_ERROR':
            return { ...state, axiosError: action?.payload };
            
        default:
            return state;
    }
};
