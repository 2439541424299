import React from "react";
import { Pagination } from "react-bootstrap";

export default function Paginador(props: any) {
  const { 
    totalPages, 
    currentPage, 
    onClick 
  } = props;

  const handleClick = (page: number) => {
    onClick(page);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];

    const visiblePages = 5; 

    let startPage = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    const extraPagesStart = Math.max(visiblePages - (endPage - startPage + 1), 0);
    startPage = Math.max(startPage - extraPagesStart, 1);

    const extraPagesEnd = Math.max(visiblePages - (endPage - startPage + 1), 0);
    endPage = Math.min(endPage + extraPagesEnd, totalPages);

    
    for (let page = startPage; page <= endPage; page++) {
      paginationItems.push(
        <Pagination.Item
          key={page}
          className={currentPage +1 === page ? "page-item active" : "page-item"}
          onClick={() => handleClick(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <Pagination className="mb-0">
      {currentPage > 0 && (
        <Pagination.Item className="page-item" onClick={() => handleClick(currentPage - 1)}>
          <i className="icon ion-ios-arrow-back"></i>
        </Pagination.Item>
      )}

      {renderPaginationItems()}

      {currentPage < totalPages && (
        <Pagination.Item className="page-item" onClick={() => handleClick(currentPage + 1)}>
          <i className="icon ion-ios-arrow-forward"></i>
        </Pagination.Item>
      )}
    </Pagination>
  );
}