import CryptoJS from 'crypto-js';

const SECRET_KEY = 'holacomoestas'; // Replace with a strong secret key

export const encryptData = (data: any): string => {
    let dataString
    try {
        dataString = JSON.stringify(data); //Intento conver data que es un objeto en un string
    } catch (error) {
        dataString = data; //si fallo la conversion, entones se que es un string y por ello puedo usarlo directamnete
    }
    return CryptoJS.AES.encrypt(dataString, SECRET_KEY).toString();
};

export const decryptData = (data: string): any => {
    if (data && data.length > 0)
        try {
            return JSON.parse(CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8)); //Intento parsear el data desencriptado en un Json y si falla
        } catch (error) {
            return CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8); //encio el data sdesencritado
        }
        
    else
        return ""

  
    // const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    
    // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    
    // return decryptedData;
};