const INIT_STATE: any = {
    novedades: undefined,
    marcas: undefined,
    rubros: undefined
};

export const principalreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'SET_NOVEDADES':
            return { ...state, novedades: action?.payload };
        case 'SET_MARCAS': 
            return { ...state, marcas: action?.payload };
        case 'SET_RUBROS':
            return { ...state, rubros: action?.payload };
        default: 
            return state;
    }
};
