const INIT_STATE: any = {
    pedidoActual: undefined,
    pedidoActualRenglones: undefined,
    pendientes: undefined,
    transportesDisponibles: undefined,
    formaFacturacion: undefined,
    condicionesVenta: undefined,
    pedidosDespachados: undefined,
    pedidosSeguimientoCancelado: [],
};

export const pedidoreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'SET_PEDIDO_ACTUAL':
            return { ...state, pedidoActual: action?.payload };

        case 'SET_PEDIDO_ACTUAL_RENGLONES':
            return { ...state, pedidoActualRenglones: action?.payload };

        case 'SET_PENDIENTES':
            return { ...state, pendientes: action?.payload };

        case 'SET_TRANSPORTE_CLIENTE':
            return { ...state, transportesDisponibles: action?.payload };

        case 'OBTENER_FORMA_FACT':
            return { ...state, formaFacturacion: action?.payload };

        case 'SET_CONDICION_VENTA':
            return { ...state, condicionesVenta: action?.payload };

        case 'SET_PEDIDOS_DESPACHADOS':
            return { ...state, pedidosDespachados: action.payload };

            case 'SET_PEDIDOS_SEGUIMIENTO_CANCELADO':
                return {
                  ...state,
                  pedidosSeguimientoCancelado: [...state.pedidosSeguimientoCancelado, action.payload]
                };
              case 'ACTUALIZAR_PEDIDOS_DESPACHADOS':
                return {
                  ...state,
                  pedidosDespachados: action.payload
                };

        case 'RESET_STATE':
            return INIT_STATE;

        default:
            return state;
    }
};
