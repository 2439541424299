import Swal from "sweetalert2";

export default function CartelInformativo(titleToShow:string ,textToShow: string) {
    Swal.fire({
        title: titleToShow,
        allowOutsideClick: false,
        confirmButtonText: "Confirmar",
        cancelButtonColor: "#38cab3",
        //text: ""
        html: textToShow
    });
}