export const pasosPedidoActual = [
    {
        title : "Bienvenido",
        target: ".paso1", 
        content: "Aqui podras seleccionar que seccion queres visualizar",
        disableBeacon: true,
    },
    {
        target: ".paso2",
        content: "Seleccioná si preferís buscar productos por código/descripción o por grupo",
        spotlightClicks: true
    },
    {
        target: ".paso3",
        content: "Escribí el código o producto que quieras. Debajo vas a poder ver los resultados de tu búsqued, en caso de que no busques nada te mostraremos el ejemplo con el elemento pala",
        spotlightClicks: true
    },
    {
        target: ".paso4",
        content: "En la siguiente lista vas a poder ver los productos encontrados",
    },
    {
        target: ".paso5",
        content: "Estos son los detalles que te mostramos de cada producto",
    },
    {
        target: ".paso6",
        content: "Aqui vas a poder ver las unidades en las que esta expresado el productos que deseas comprar",
        disableScrolling: true
    },
    {
        target: ".paso7",
        content: "Aqui vas a poder visualizar el stock disponible",
    },
    {
        target: ".paso8",
        content: "Escribe una cantidad y dale click a la tilde para agregar el articulo",
        spotlightClicks: true
    },
    {
        target: ".paso9",
        content: "Cambia de tab para elegir la nueva seccion",
        spotlightClicks: true
    },
    {
        target: ".paso10",
        content: "Aqui vas a poder ordenar los productos que añadiste por orden alfabetico o por ultimo pedido",
        spotlightClicks: true
    },
    {
        target: ".paso11",
        content: "Aqui vas a poder ordenar los productos que añadiste por orden alfabetico o por ultimo pedido",
    },
    {
        target: ".paso12",
        content: "Aqui vas a ver tu pedido",
    }, 
    {
        target: ".paso13",
        content: "Aqui vas a poder conocer mas a detalle el stock disponible para cumplir con el pedido",
        spotlightClicks: true
    },
    {
        target: ".paso14",
        content: "Si alguno de los productos que compraste no cuenta con stock podras verlo aqui en tus Pendientes Anteriores",
        spotlightClicks: true
    },
    {
        target: ".paso15",
        content: "Si tienes un pendiente podras verlo en esta lista",
        spotlightClicks: true
    },
    
    {
        target: ".paso16",
        content: "Tienes la opcion de repedir o de eliminar este pendiente",
        spotlightClicks: true
    },
    
    {
        target: ".paso17",
        content: "Con tu pedido ya listo, podes gestionar el envio",
        spotlightClicks: true
    },
    {
        target: ".paso18",
        content: "Elegi el metodo de transporte de tu pedido",
        spotlightClicks: true
    },
    {
        target: ".paso19",
        content: "Elegi el metodo de envio de tu pedido",
        spotlightClicks: true
    },
    {
        target: ".paso20",
        content: "Elegi una condicion de venta de tu pedido",
        spotlightClicks: true
    },
    {
        target: ".paso21",
        content: "Si lo deseas podes agregar tu Nro de Orde de Compra",
        spotlightClicks: true
    },
    {
        target: ".paso22",
        content: "Ya podes pedir!!",
        spotlightClicks: true
    },

];

export const pasosPDV = [
    {
        title : "Bienvenido",
        target: ".paso1", 
        content: "Aqui podes buscar el producto que quieras, en caso de no buscar ningun producto te mostraremos el ejemplo con el elemento pala ",
        disableBeacon: true,
        spotlightClicks: true
    },
    {
        target: ".paso2", 
        content: " Aqui podes seleccionar el tipo de busqueda que queres realizar",
        disableBeacon: true,
        spotlightClicks: true
    },
    {
        target: ".paso3", 
        content: "Aqui vas a poder ver los productos que buscaste",
        disableBeacon: true,
        spotlightClicks: true
    },
    {
        target: ".paso4",
        content: "Aqui vas a poder ver la unidad en la que esta expresada tu producto",
        spotlightClicks: true
    },
    {
        target: ".paso5",
        content: "Aqui vas a poder ver el costo de la unidad",
    },
    {
        target: ".paso6",
        content: "Aqui podes ver el primer precio sugerido de venta ",
    },
    {
        target: ".paso7",
        content: "Aqui vas a poder ver el segundo precio sugerido de venta ",
        disableScrolling: true
    },
    {
        target: ".paso8",
        content: "Aqui vas a poder ver el stock disponible",
        disableScrolling: true
    },
    {
        target: ".paso9",
        content: "Elegi una cantidad y confirma la compra haciendo click en el tilde",
        disableScrolling: true,
        spotlightClicks: true
    },

];

export const pasosMisDatos = [
    {
        title : "Bienvenido",
        target: ".paso1", 
        content: "Aqui podes ver las preferencias de tu cuenta",
        disableBeacon: true,
        spotlightClicks: true
    },
    {
        target: ".paso2",
        content: "Aqui podes seleccionar el primer margen de ganancia que queres",
        disableScrolling: true,
        spotlightClicks: true
    },

    {
        target: ".paso3",
        content: "Aqui podes seleccionar el segundo margen de ganancia que queres",
        disableScrolling: true,
        spotlightClicks: true
    },
    {
        target: ".paso4",
        content: "Podes seleccionar si mostrar o no los costos",
        disableScrolling: true,
        spotlightClicks: true
    },
    {
        target: ".paso5",
        content: " Podes seleccionar si mostrar o no los costos con IVA",
        disableScrolling: true,
        spotlightClicks: true
    },
    {
        target: ".paso6",
        content: "Podes elegir si ver los pedido en tu cuenta corriente",
        disableScrolling: true,
        spotlightClicks: true
    },
    {
        target: ".paso7",
        content: "Aqui podes ver tus datos personales",
        disableScrolling: true,
        spotlightClicks: true
    },



];

