import { Col, Row } from "react-bootstrap";
import {useEffect, useState } from "react";
import apiFc from "../../services/APIFc";
import FcCarousel from "../FcCarousel/FcCarousel";
import CardGrupo from "../Card Grupos/CardGrupo";
import { AlertDialogInterface } from "./Interfaces";
import ExtraLargeModal from "./ExtraLargeModal";

interface Articulo {
  articuloCodigo: string;
  articuloDescripcion: string;
  articuloUnidadVenta: string;
  articuloPrecio: number;
  articuloPrecioVentaSug1: number;
  articuloPrecioVentaSug2: number;
  articuloTasaIVA: number;
  articuloStock: number;
  articuloStockMin: number;
  articuloStockMax: number;
  articuloImagenGXI: string | null;
  articuloGrupoId: { articuloGrupoImagenGXI: string | null } | null;
}

export default function NuevosProductosInicio() {
  const [sugerenciaArticuloGrupo, setSugerenciaArticuloGrupo] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success',
    timeOut: 2000,
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  const handleOpenModal = (articulo: any) => {
    setSelectedGroup(articulo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedGroup({});
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    buscarSugerenciaRubroArticuloGrupos();
  }, []);

  function buscarSugerenciaRubroArticuloGrupos() {
    apiFc.get(`/web/principal/grupoproducto/sugerencias`).then(res => {
      setSugerenciaArticuloGrupo(res.data);
    });
  }

  return (
    <div>
      <ExtraLargeModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedGroup={selectedGroup} 
        alertDialog={alertDialog}
        // setAlertDialog={setAlertDialog}
        windowWidth={windowWidth}
      />
      <div className='mg-b-10 mg-t-30'>
        <h1 className="display-6 tx-20 tx-bold"><strong>Nuevos productos</strong></h1>
      </div>
      <Row>
        <FcCarousel items={sugerenciaArticuloGrupo?.map((articuloGrupo: any, i: number) => {
          return (
            (i < 13) ? (
              <Col xs={6} sm={3} xl={2} xxl={2} lg={3} md={3} key={articuloGrupo.id}>
                <CardGrupo
                  articuloGrupo={articuloGrupo}
                  onClick={() => {
                    handleOpenModal(articuloGrupo);
                  }}
                />
              </Col>
            ) : []
          );
        })} />
      </Row>
    </div>
  )
}
