import React, { Ref, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Autosuggest from 'react-autosuggest';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { consultaArticuloHeader, filterProduct } from "../../redux/actions/grid";
import apiFc from "../../services/APIFc";
import './barrabusqueda.css';

export interface respuestaBusqueda {
    type: number; //1: buscador 2: grupo
    value: any; // valor buscado
    text: string; // valor buscado
}

interface barraBusquedaProps {
    onSearch: (a: respuestaBusqueda) => void; // Define the onClick prop as a function
    ref?: Ref<any>;
    classNameContainer?: string;
    classNameInput?: string;
    buttonContent?: any;
    handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

BarraBusquedaSugerida.defaultProps = {
    conIcono: true
};

export function BarraBusquedaSugerida(props: barraBusquedaProps) {

    const buscadorRef = useRef<any>(props.ref);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {

    }, []);

    const handleChange = (event: React.FormEvent<HTMLInputElement>, { newValue, method }: Autosuggest.ChangeEvent) => {
        setValue(newValue);
    };
    

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.key === 'Enter' || event.key === 'NumpadEnter') && value.trim() !== '') {
            event.preventDefault(); // Evitar la acción predeterminada del formulario
            const searchValue = value.trim(); // Obtener el valor de búsqueda después de eliminar espacios en blanco
            setValue(''); // Reiniciar el valor del input
            props.onSearch({ type: 1, value: searchValue, text: searchValue }); // Llamar a onSearch con el valor actualizado
        }
    };

    const handleSuggestionsFetchRequested = ({ value }: Autosuggest.SuggestionsFetchRequestedParams) => {
        setIsLoading(true);
        apiFc.get(`/articuloGrupos/page?filter=${value}&page=0&rows=20`)
            .then(res => {
                setIsLoading(false);
                setSuggestions(res.data.content.map((sugerencia: any) => ({
                    value: sugerencia.articuloGrupoId,
                    label: `${sugerencia.articuloGrupoDescripcion}`,
                    image: `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/${sugerencia.articuloGrupoImagenGXI}`,
                })));
            })
            .catch(err => {
                setIsLoading(false);
            });
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (event: React.FormEvent<HTMLInputElement>, { suggestion, method }: Autosuggest.SuggestionSelectedEventData<any>) => {
        //Si el usuario selecciono una opcion de las sugerencias
        (event.target as HTMLInputElement).value = '' //Vacio el buscado
        setValue("") //refuerzo de vacio
        props.onSearch({ type: 2, value: suggestion.value, text: suggestion.label }); //repondono en el onSearch
    };

    const handleTouch = (event: React.TouchEvent<HTMLDivElement>) => {
        // Evita la propagación del evento táctil
        event.stopPropagation();
        // Resto del código
    };
    

    const renderSuggestion = (suggestion: any) => (
        <div onTouchStart={handleTouch}>
            {suggestion.label}
        </div>
    );


    const inputProps: Autosuggest.InputProps<any> = {
        placeholder: 'Buscador...',
        value,
        onChange: handleChange as any,
        onKeyDown: handleKeyDown as any,
        onBlur: (e: any) => e.target.value = '',
        onFocus: (event: any) => event.target.select(),
        type: "search",
        className: "custom-input" // Agregar la clase personalizada aquí
    };
    const customTheme: Autosuggest.Theme = {
        container: props.classNameContainer || 'react-autosuggest__container',
        containerOpen: props.classNameContainer || 'react-autosuggest__container--open',
        input: props.classNameInput || 'react-autosuggest__input',
        inputOpen: props.classNameInput || 'react-autosuggest__input--open',
        inputFocused: props.classNameInput || 'react-autosuggest__input--focused',
        suggestionsContainer: 'react-autosuggest__suggestions-container',
        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
        suggestionsList: 'react-autosuggest__suggestions-list',
        suggestion: 'react-autosuggest__suggestion',
        suggestionFirst: 'react-autosuggest__suggestion--first',
        suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
        sectionContainer: 'react-autosuggest__section-container',
        sectionContainerFirst: 'react-autosuggest__section-container--first',
        sectionTitle: 'react-autosuggest__section-title'
    };
    //
    return (
        <>
            <Autosuggest
                ref={buscadorRef}
                theme={customTheme}
                suggestions={suggestions}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={handleSuggestionsClearRequested}
                onSuggestionSelected={handleSuggestionSelected}
                getSuggestionValue={suggestion => suggestion.label}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
            {props.buttonContent}
        </>
    );
}

export default BarraBusquedaSugerida;