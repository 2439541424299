import React, { ReactNodeArray, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Card, Col, Row } from 'react-bootstrap';
import APIFc from '../../../services/APIFc';
import { formatearFecha } from '../../../functions/Procedimientos';
import Swal from 'sweetalert2';
import reactStringReplace from 'react-string-replace';
import { useSelector } from 'react-redux';
import { decryptData } from '../../../functions/cryptoUtils';
const Descargas = () => {

  const [descargas, setDescargas] = useState<any>()

  useEffect(() => {
    setShowSwal(false);
    APIFc.get(`archivos/enableds`)
      .then(res => {
        setDescargas(res.data)
      }).catch(err => {
      });
  }, [])

  useEffect(() => {
  }, [descargas])

  // src={require("../../../assets/img/files/word.png")}
  // src={require("../../../assets/img/files/file.png")}
  // src={require("../../../assets/img/files/folder.png")}
  // src={require("../../../assets/img/files/doc.png")}
  // src={require("../../../assets/img/files/image.png")}

  //Primaryalert
  const [showSwal, setShowSwal] = useState(false);
  function SwalPopUp() {
    Swal.fire({
      title: "Preparando!",
      icon: "success",
      allowOutsideClick: true,
      confirmButtonText: "Aguarde que la descarga comience",
      cancelButtonColor: "#38cab3",
      text: "Estamos generando su reporte, al comenzar la descarga puede cerrar esta ventana con escape ",
      timerProgressBar: true,
      backdrop: `
          rgba(249, 127, 127, 0.8)
          left top
          no-repeat
        `,
      didOpen: () => {
        Swal.showLoading()
      }
    }).then((result) => {
      handleClose()
    });
  }

  const md5 = require('md5');
  const  user = useSelector((store: any) => decryptData(store.authreducer.user));
  const [downloadStarted, setDownloadStarted] = useState(false);

  const handleClick = () => {
    setShowSwal(true);
  }

  const handleClose = () => {
    setShowSwal(false);
  };

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Descargas</span>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      <>{showSwal && SwalPopUp()}</>
      {/* <!-- row --> */}
      <Row>
        <Col lg={12} xl={12}>
        <Card className="custom-card">
          <Card.Body className=" ht-100p">
            <div>
              <h6 className="card-title mb-1">Ahora los reportes de informacion son generado automaticamente, la descarga puede demorar unos segundos en comenzar</h6>
            </div>
          </Card.Body>
        </Card>
          <Row>
            {descargas
              ? descargas.map((descarga: any, index: any ) => (
                <Col sm={6} xl={2} md={4} id={index}>
                  <Card className=" p-0 ">
                    <Card.Body className="pt-0 text-center">
                      <div className="file-manger-icon">
                        <a
                          href={descarga.archivosDescargaLink
                            .replace('CC', user.clienteCodigo)
                            .replace('HH', md5(`cV0puOlx${user.clienteCodigo}${new Date().getDate().toString().padStart(2, '0')}`))
                            .replace('FF', new Date().toISOString().slice(0, 10))}
                          download
                          onClick={e => setTimeout(handleClick,1000)}
                        >
                          
                          <img
                            src={require(`../../../assets/img/files/${descarga.archivosDescargaIcono}`)}
                            alt=""
                            className="br-7"
                          />
                        </a>
                      </div>
                      <h6 className="mb-1 h-40 font-weight-semibold">{descarga.archivosDescargaDetalle}</h6>
                      <span className="text-muted">
                        {
                          formatearFecha({
                            date: descarga.archivosDescargaFechaUltimaActualizacion,
                          })
                        }
                      </span>
                    </Card.Body>
                  </Card>
                </Col>))
              :
              <div style={{ height: "250px", alignItems: "center", position: "relative" }}>
                <span style={{ position: "absolute", bottom: "50%", left: "50%", whiteSpace: "nowrap" }}>No hay resultados</span>
              </div>
            }
          </Row>
        </Col>
      </Row>
      {/* <!-- End Row --> */}
    </div>
  )
}
export default Descargas;

