import React, { useEffect, useState } from 'react'
import "./cardrubros.css"
import { useNavigate } from 'react-router-dom';
import LazyImage from '../../functions/LazyImage';

const CardRubros = (props: any) => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const RubroImagen = windowWidth < 700 
    ? `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Rubros/PortadaRubros/${props.rubro.id}_${props.rubro.id}.jpg`
    : `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Rubros/PortadaRubros/${props.rubro.id}.jpg`;

  return (
    <div className="container-card-rubros" onClick={() => navigate((window.location.href.includes("/principal")) ? `/principal/catalogorubro?rubroId=${props.rubro.id}` : `/catalogorubro?rubroId=${props.rubro.id}`)}>
      <div className="img-container">
        <LazyImage
          className="img-fix-card-rubros"
          key={props.rubro.id}
          src={RubroImagen}
          alt="Lazy-loaded Image"
          onError={(e: any) => {
            e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
          }} />
      </div>
    </div>
  )
}

export default CardRubros;
