import { Col, Row,} from "react-bootstrap";
import {useEffect, useState } from "react";
import apiFc from "../../services/APIFc";
import FcCarousel from "../FcCarousel/FcCarousel";
import CardGrupo from "../Card Grupos/CardGrupo";
import { useDispatch, useSelector } from "react-redux";
import { setNovedades } from "../../redux/actions/principal";
import { decryptData } from "../../functions/cryptoUtils";
import { AlertDialogInterface } from "./Interfaces";
import ExtraLargeModal from "./ExtraLargeModal";


export default function ProductosMasCompradosInicio() {
    const [masCompradosArticuloGrupo, setMasCompradosArticuloGrupo] = useState([])
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState<any>({});
    const user = useSelector((store: any) => decryptData(store.authreducer.user));
    const [articulosGrupo, setArticulosGrupo] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDetalle, setShowDetalle] = useState(false);
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
      id: "1",
      visible: false,
      severity: 'success', // severity="error" / "warning" / "info" / "success"
      timeOut: 2000, // severity="error" / "warning" / "info" / "success"
      cabecera: "Agregado!",
      texto: "el producto fue agregado.",
    });
    const handleOpenModal = (articulo: any) => {
      setSelectedGroup(articulo);
      setShowModal(true);
    };
    const handleCloseModal = () => {
      setShowModal(false);
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        buscarSugerenciaRubroArticuloGrupos();
    }, []);

      useEffect(() => {
    if (selectedGroup.articuloGrupoId > 0) {
      try {
        apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
          setArticulosGrupo(res.data)
          setShowDetalle(true)
        })
      } catch (error) { }
    }
  }, [selectedGroup])

    function buscarSugerenciaRubroArticuloGrupos() {
        apiFc.get(`/web/articulos/vendidos`)
        .then(res => {
          setMasCompradosArticuloGrupo(res.data);
        }).catch(err => {
          dispatch(setNovedades(null))
        });
      }
  
  return (
    <div >
        <ExtraLargeModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedGroup={selectedGroup} 
        alertDialog={alertDialog}
        // setAlertDialog={setAlertDialog}
        windowWidth={windowWidth}
      />
      {(window.location.href.includes("/principal"))
        ?
        <>
          <div className='mg-b-10 mg-t-30'>
            <h1 className=" display-6 tx-20 tx-bold"><strong>Tus productos mas comprados</strong></h1>
          </div>
          <Row >
            <FcCarousel items={masCompradosArticuloGrupo?.map((articuloGrupo: any, i) => (
                <Col xs={6} sm={3} xl={2} xxl={2} lg={3} md={3} key={articuloGrupo.id}>
                    <CardGrupo articuloGrupo={articuloGrupo} onClick={() => {
                      setSelectedGroup(articuloGrupo);
                      handleOpenModal(articuloGrupo);
                      
                    }} />
                  </Col>
            ))} />
          </Row>
        </>
        :
        <></>
      }
    </div>
  )
}

