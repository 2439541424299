import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import apiFc from "../../../../services/APIFc";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from "react";
import { isMobile } from 'react-device-detect';
import Slider from "react-slick";
import { formatearNumero } from "../../../../functions/Procedimientos";
import InputCarritoCompra from "../../../common/InputCarritoCompra";
import { AlertDialogInterface } from "../../../common/Interfaces";
import { useLocation, useParams } from "react-router-dom";

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 2,
  autoplay: true,
  speed: 1000,
  arrows: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 4
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};


const Ofertas = () => {
  const dispatch = useDispatch();
  const { pedidoActualRenglones } = useSelector((store: any) => store.pedidoreducer);
  const [data, setData] = useState<any[]>([]);
  const [ofertas, setOfertas] = useState([]);
  const [productoOferta, setProductoOferta] = useState([]);
  const [filtroLocal, setFiltroLocal] = useState<string>('');
  const location = useLocation();
  const { articulos } = location.state || { articulos: [] };
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  //Administrador de las tabs
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });



  useEffect(() => {
    setAlertDialog({ ...alertDialog, visible: false })
    window.scrollTo(0, 0)
    let date = new Date()
    date.setDate(date.getDate() - 30);
    apiFc.get(`/web/articulo/oferta`).then(res => {
      setAlertDialog({ ...alertDialog, visible: true, cabecera: "Ofertas recibidas con exito", texto: `-`, severity: "success" })
      setOfertas(res.data);
    }).catch(err => {
      setOfertas([]);
      setAlertDialog({ ...alertDialog, visible: true, cabecera: "Problemas cargando ofertas", texto: `Error 405`, severity: "success" })
    });
  }, [])


  useEffect(() => { }, [ofertas]);



  useEffect(() => {
    if (id) {
      apiFc.get(`/web/articulo/oferta/${id}/articulos`)
        .then(res => {
          setProductoOferta(res.data);
          setLoading(false);
        }).catch(err => {
          console.log("Error:", err);
          setLoading(false);
        });
    }
  }, [id]);

  if (loading) {
    return (
      <div className="text-center mg-b-20">
        <Spinner
          animation="border"
          className="spinner-border"
          role="status"
        >
          <span className="sr-only">Cargando...</span>
        </Spinner>
      </div>
    );
  }

  const obtenerArticulosOferta = (item: any) => {
    apiFc.get(`/web/articulo/oferta/${item.id}/articulos`)
      .then(res => {
        setProductoOferta(res.data)
      }).catch(res => {
        setData(data)
      });
  }


  const handleInput = (event: any) => {
    const value = event.target.value;
    setFiltroLocal(value);
  };

  function Mensaje(props: any) {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref,
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertDialog({ ...alertDialog, visible: false });
    };
    return (
      <Snackbar
        open={alertDialog.visible}
        autoHideDuration={alertDialog.timeOut}
        onClose={handleClose}
        //Aca decido si es mobile muestro arriba el cartelito, sino abajo
        anchorOrigin={(isMobile) ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose}
          severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
          sx={{ width: '100%' }}>
          <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
        </Alert>
      </Snackbar>
    )
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Ofertas habilitadas</span>
          {alertDialog.visible && <Mensaje ></Mensaje>}
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      {/* <!-- row --> */}

      <Col xl={12} lg={12} md={12} className="mb-md-0 mb-3">
        <Card className="custom-card">
          <Card.Body className=" ht-100p">
            <div>
              <h6 className="card-title mb-1">Haga click para conocer los productos de la oferta</h6>
            </div>
            <div id="basicSlider">
              <div className="MS-content">
                <Slider {...settings}>
                  {ofertas &&
                    ofertas.map((oferta: any) => {
                      return (
                        <div className="item" key={oferta.id}>
                          <img
                            src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/${oferta.imagenUrl}`}
                            alt="product"
                            onError={(e: any) => {
                              e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                            }}
                            className="br-5"
                            onClick={() => obtenerArticulosOferta(oferta)}
                          />
                        </div>
                      )
                    })}
                </Slider>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="p-2">
            <div className="input-group">
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Buscar ..."
                onChange={handleInput}
              />
              <span className="input-group-append">
                <Button variant="" className="btn btn-primary" type="button">
                  Buscar
                </Button>
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col xl={12} lg={12} md={12}>

        <Row className="row-sm">
          {productoOferta.length === 0 ? (
            <div className="text-center mg-b-20 ">No hay productos en oferta.</div>
          ) : (
            <div className="product-details table-responsive text-nowrap">
              <Table className="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th className="text-start">Producto</th>
                    <th>Pedir</th>
                    <th>Cantidad</th>
                    <th>Descuento</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {productoOferta.filter((articuloFilter: any) => {
                    const descripcionCoincide = articuloFilter.articuloDescripcion.toLowerCase().includes(filtroLocal.toLowerCase());
                    const codigoCoincide = articuloFilter.articuloCodigo.toString().includes(filtroLocal);
                    return descripcionCoincide || codigoCoincide;
                  }).map((articuloOferta: any) => (
                    <Fragment key={articuloOferta.articuloCodigo}>
                      <tr>
                        <td rowSpan={articuloOferta.ofertaListado.length + 1}>
                          <div className="media">
                            <div className="card-aside-img">
                              <img className="pic-1" src={`https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/${articuloOferta.articuloImagenURL}`} alt="product"
                                onError={(e: any) => {
                                  e.target.src = `https://appwebferretera.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                                }}
                              />
                            </div>
                            <div className="media-body">
                              <div className="card-item-desc mt-0">
                                <h6 className="font-weight-semibold mt-0 text-uppercase">
                                  {articuloOferta.articuloDescripcion}
                                </h6>
                                <dl className="card-item-desc-1">
                                  <dt>Código: </dt>
                                  <dd>{articuloOferta.articuloCodigo}</dd>
                                </dl>
                                <dl className="card-item-desc-1">
                                  <dt>U.v: </dt>
                                  <dd>Paquete</dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td rowSpan={articuloOferta.ofertaListado.length + 1} className="text-webkit-center">
                          <InputCarritoCompra
                            articulo={articuloOferta}
                            origen="Oferta"
                            onEvent={(e: any) => setAlertDialog(e)}
                          />
                        </td>
                      </tr>
                      {articuloOferta.ofertaListado.map((descuento: any) => (
                        <tr key={descuento.id}>
                          <td className="text-center text-lg text-medium font-weight-bold tx-15">
                            {descuento.desdeCantidad}
                          </td>
                          <td className="text-center text-lg text-medium font-weight-bold tx-15">
                            {`${descuento.porcentajeDescuento} %`}
                          </td>
                          <td className="text-center text-lg text-medium font-weight-bold tx-15">
                            {formatearNumero({ valor: descuento.precioOferta })}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Row>
      </Col>

      {/* <!-- row closed --> */}
    </div >
  );
}



export default Ofertas

