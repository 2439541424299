const INIT_STATE: any = {
    gridPedidoArticulosBusqueda: undefined,
    articuloBuscadorHeader: undefined
};

export const gridreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'FILTER_GRID_PEDIDOS_ARTICULOS_BUSQUEDA':
            return { ...state, gridPedidoArticulosBusqueda: action?.payload };

        case 'FILTER_HEADER_SEARCH':
            return { ...state, articuloBuscadorHeader: action?.payload };

        default:
            return state;
    }
};
